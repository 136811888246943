import jquery from "jquery";
window.$ = window.jquery = jquery;
import "bootstrap";
require.context("../images", true, /\.(mp4|webm|ogv)$/i);
require("normalize.css/normalize.css");
import "../styles/application.scss";
import cities from "./config/cities";
require("bootstrap-datepicker");
require("bootstrap-datepicker/js/locales/bootstrap-datepicker.ru.js");
require("./jquery_ellipsis.js");
require("./jquery_nivo_slider.js");
require("@fancyapps/fancybox");
require("@fancyapps/fancybox/dist/jquery.fancybox.css");
require("bootstrap-select");
// require("social-share-button");
// require("bootstrap/alert");
// require("bootstrap/dropdown");
// require("share-buttons/dist/share-buttons.js");
require("./share-buttons.js");
import "@fortawesome/fontawesome-free/js/all";
import "@fortawesome/fontawesome-free/css/all.css";
import "./flickity_pkgd_min.js";



// import our custom styles from 'app/javascript/stylsheets/carousel.css
// import "stylesheets/carousel.css";

require("@rails/ujs").start();

const images = require.context("../images", true);

function setDefaultCity(code) {
  const citys =
    document.querySelector(".a-form")?.getElementsByTagName("option") || [];
  for (let i = 0; i < citys.length; i++) {
    if (citys[i].value === code) {
      citys[i].selected = true;
    }
    // citys[i].selected = true;
  }
}
function slider_height() {
  // функция увеличения высоты слайдера (carousel-wrapper) по самому высокому элементу
  var mh = 0;
  $("#flic_slide .carousel-cell").each(function () {
    var h_block = $(this).height();
    if (h_block > mh) {
      mh = h_block;
    };
  });
  $("#flic_slide .flickity-viewport").height(mh + 55);
  if ($(window).width() <= '540')
    $("#flic_slide .carousel-wrapper").height(mh + 100);
  else
    $("#flic_slide .carousel-wrapper").height(mh + 145);
  // end
}
$(window).on('resize', function () {
  if ($("#flic_slide").length)
    slider_height();
});
$(document).ready(function () {


  //Обработка появления иконки приглашения для связи через WHATSUPP или телегу
  $(window).scroll(function (e) {
    if ($(window).scrollTop() > 300) {
      $(".messagebutton_section").show();
    }
    else {
      $(".messagebutton_section").hide();
    }
  });




  if ($("#flic_slide").length)
    slider_height();

  $(".fancybox").fancybox();
  $('.link').fancybox({
    openEffect: 'none',
    closeEffect: 'none',
    helpers: {
      media: {}
    }
  });
  $('.artic_link').fancybox({
    'type': 'iframe',
    'width': '90%', //or whatever you want
    'height': '80%'
  });
  if ($('#slider').css('display') != 'none') {
    $('#slider').nivoSlider();
  }
  $(".datepicker")
    .datepicker({
      language: "ru",
    })
    .on("changeDate", function (e) {
      window.location = window.location.pathname + `?starts_at=${e.format()}&h=${gon.cat_id}`;
    });

  // yearRange : $('.datepicker_year').attr('data-year-range')
  $(".year_date").click(function () {
    window.location = window.location.pathname + `?starts_at=` + $(this).attr('dateyear');
  });
  $('select').selectpicker();

  // ellipsis
  $(".eclips.cli_sh_2").ellipsis({ lines: 2 });
  $(".eclips.cli_sh_4").ellipsis({ lines: 4 });
  $(".eclips.cli_sh_5").ellipsis({ lines: 5 });
  $(".eclips.cli_sh_6").ellipsis({ lines: 6 });
  $(".eclips.cli_sh_7").ellipsis({ lines: 7 });
  $(".eclips.cli_sh_10").ellipsis({ lines: 10 });
  $(".eclips .ellip-line").click(function () {
    $(this).toggleClass("ope");
  });
  // для страниц, где есть табы
  $('a[data-toggle="pill"]').on("click", function (e) {
    var pos = 0;
    // если есть #v-pills-tabContent и ширина окна меньше 560
    if ($("#v-pills-tabContent").length > 0 && $(window).width() <= '560') {
      pos = $("#v-pills-tabContent").offset().top - 20;
    }
    $(window).scrollTop(pos);
  });
});

$(document).ready(function () {
// $('#navrazd').hide();
  if (gon.show_modal) {
    var selector = "#link_to_addi_text" + gon.show_modal;
    $(selector).click();
  }
  if (gon.city) {
    const city = gon.city;
    Cookies.set("na_branch", city);
    Cookies.set("na_init_branch", city);
  }
  if (gon.city_ex) {
    const city_ex = gon.city_ex;
    Cookies.set("na_branch", city_ex);
    Cookies.set("na_init_branch", city_ex);
  }
  window.initCity = Cookies.get("na_init_branch") || "msk";
  window.currentCity = Cookies.get("na_branch") || window.initCity;
  window.initAcceptCity = function initAcceptCity(e) {
    Cookies.set("na_branch", window.initCity);
    Cookies.remove("na_init_branch");
    $("#initCityModalBtn").click();
  };
  window.particulAcceptCity = function particulAcceptCity(e) {
    Cookies.set("na_branch", window.initCity);
    Cookies.remove("na_init_branch");
    $("#particulCityModalBtn").click();
  };
  window.initChangeCity = function initChangeCity(e) {
    $("#initCityModalBtn").click();
    $("#cityModalBtn").click();
  };
  if (gon.city) {
    $("#particulCityModalBtn").click();
    setTimeout(function () {
      window.location = "/"
    }, 3000);
  }
  if (gon.city_ex) {
    if (gon.show_modal) {
      $("#particulCityModalBtn").click();
      window.location = "./?show_modal=" + gon.show_modal;
    }
    else {
      $("#particulCityModalBtn").click();
      setTimeout(function () {
        window.location = "./"
      }, 3000);
    }
  }
  $(".city-select").on("click", (event) => {
    const city = event.target.getAttribute("value");
    Cookies.set("na_branch", city);
    Cookies.remove("na_init_branch");
    if (gon.city_ext) {
      window.location = "./?city_ext=" + Cookies.get("na_branch");
    }
    else {
      location.reload();
      // window.location = "./";
    }
    // location.reload();
  });

  $(".city_change").on("click", (event) => {
    const city = event.target.getAttribute("branch_slug");
    Cookies.set("na_branch", city);
    Cookies.set("na_init_branch", city);
    $(".js-a-init-city").text(
      cities[city] && cities[city].normal
    );
    $("#particulCityModalBtn").click();
    $("html, body").animate({ scrollTop: "0px" }, 1000);

    setTimeout(function () {
      location.reload();
    }, 3000);
  });

  if (!Cookies.get("na_branch") && window.initCity) {
    $("#initCityModalBtn").click();
  } else {
  }
  //
  $(".js-a-current-city").text(
    cities[window.currentCity] && cities[window.currentCity].normal
  );
  $(".js-a-current-city-in").text(
    cities[window.currentCity] && cities[window.currentCity].in
  );
  $(".js-a-init-city").text(
    // cities[window.initCity] && cities[window.initCity].normal    
    cities[window.currentCity] && cities[window.currentCity].normal
  );

  navItemSelect();
  hideBlock(window.currentCity);

  setDefaultCity(window.currentCity);

  // $('a.navrazd').on('click', function(event) {

  //       if($(this).attr('href').charAt(0)!='#')return true;

  //       event.preventDefault();

  //       var hash = this.hash;

  //       $('html, body').animate({scrollTop: $(hash).offset().top}, 900);

  //   });
});

$(window).on("load", function () {
  $('#navrazd').show();
  const eventImages = document.getElementsByClassName("a-event-img");
  if (eventImages.length > 0) {
    const eventPanels = document.getElementsByClassName("a-event-panel");
    const eventContent = document.getElementsByClassName("a-event-content");
    [].map.call(eventImages, (el, idx) => {
      let rgb = getAverageRGB(el);
      let font_color =
        rgb.r * 0.299 + rgb.g * 0.587 + rgb.b * 0.114 > 186
          ? "#000000"
          : "#ffffff";
      eventPanels[
        idx
      ].style.background = `linear-gradient(to bottom, rgba(${rgb.r},${rgb.g},${rgb.b}, 0) 0%, rgba(${rgb.r},${rgb.g},${rgb.b}, 1) 100%)`;
      eventContent[idx].style.background = `rgba(${rgb.r},${rgb.g},${rgb.b})`;
      eventContent[idx].style.color = font_color;
    });
  }
});

function getAverageRGB(imgEl) {
  let blockSize = 5, // only visit every 5 pixels
    defaultRGB = { r: 0, g: 0, b: 0 }, // for non-supporting envs
    canvas = document.createElement("canvas"),
    context = canvas.getContext && canvas.getContext("2d"),
    data,
    width,
    height,
    i = -4,
    length,
    rgb = { r: 0, g: 0, b: 0 },
    count = 0;

  if (!context) {
    return defaultRGB;
  }

  height = canvas.height =
    imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
  width = canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;

  context.drawImage(imgEl, 0, 0);

  try {
    data = context.getImageData(0, 0, width, height);
  } catch (e) {
    console.log("security error, img on diff domain", e);
    return defaultRGB;
  }

  length = data.data.length;

  while ((i += blockSize * 4) < length) {
    ++count;
    rgb.r += data.data[i];
    rgb.g += data.data[i + 1];
    rgb.b += data.data[i + 2];
  }

  // ~~ used to floor values
  rgb.r = ~~(rgb.r / count);
  rgb.g = ~~(rgb.g / count);
  rgb.b = ~~(rgb.b / count);

  return rgb;
}

// функция подчеркивания активного пункта меню навигации
function navItemSelect() {
  let pageId = document
    .querySelector("[data-id-page]")
    ?.getAttribute("data-id-page"),
    navItem = document.querySelector(`[data-id-nav=${pageId}]`);

  if (pageId === navItem?.getAttribute("data-id-nav")) {
    navItem?.classList?.add("active-desktop-link");
  }
}

function hideBlock(value) {
  if (document.getElementById("a-blk")) {
    if (value != "msk" || value != "spb") {
      document.getElementById("a-blk").hidden = true;
    } else {
      document.getElementById("a-blk").hidden = false;
    }
  }
}



$(document).ready(function () {
  const url = window.location.href;
  const hash = url.substring(url.indexOf("#"));

  if (url.indexOf("#") > 0) {
    const activeTab = url.substring(url.indexOf("#") + 1);
    $("#" + activeTab).tab("show");
  }

  $(".nav-pills")
    .find("a")
    .each(function (key, val) {
      $(val).click(function (e) {
        e.preventDefault();
        history.replaceState(null, null, "#" + this.id);
      });
    });
});
// для форм курса с выбором даты .length .eq(index)
$(document).ready(function () {
  $('#enrollment_branch_id').on('change', function () {
    // alert($('#enrollment_id_branch').val() +' '+this.value);
    if (this.value == $('#enrollment_id_branch').val()) {
      $('#vivod_mesta').show();
      $('#course_price').show();
      $('.vibrat_datu').show();
    }
    else {
      $('#vivod_mesta').hide();
      $('#course_price').hide();
      $('.vibrat_datu').hide();
    }
    $('#enrollment_event_id').val('');
    // alert( gon.course_ar[0][5]);
  });
  $('#enrollment_date_time').on('change', function () {
    // определим номер выделенного элемента
    var select_ind = $('#enrollment_date_time option:selected').index() - 1;
    if (select_ind < 0)
      $('#enrollment_event_id').val('');
    else
      $('#enrollment_event_id').val(gon.course_ar[select_ind][5]);
  });

});




