export default {
  msk: { normal: "Москва", in: "Москве" },
  msk_arbat: { normal: "Москва (филиал на Арбате)", in: "Москве на Арбате" },
  msk_basm: { normal: "Москва (филиал на Басманной)", in: "Москве на Басманной" },
  msk_srt: { normal: "Москва (филиал на Сретенке)", in: "Москве на Сретенке" },
  msk_cvet: { normal: "Москва (филиал на Цветном)", in: "Москве на Цветном" },
  msk_zld: { normal: "Москва (филиал в Зеленограде)", in: "Зеленограде" },
  spb: { normal: "Санкт-Петербург", in: "Санкт-Петербурге" },
  spb_moyk: {
    normal: "Санкт-Петербург (филиал на Мойке)",
    in: "Санкт-Петербурге на Мойке",
  },
  spb_prkp: {
    normal: "Санкт-Петербург (филиал на Парке Победы)",
    in: "Санкт-Петербурге на Парке Победы",
  },
  klg: { normal: "Калининград", in: "Калининграде" },
  sam: { normal: "Самара", in: "Самаре" },
  vng: { normal: "Великий Новгород", in: "Великом Новгороде" },
  vld: { normal: "Владивосток", in: "Владивостоке" },
  vgg: { normal: "Волгоград", in: "Волгограде" },
  vrn: { normal: "Воронеж", in: "Воронеже" },
  ekb: { normal: "Екатеринбург", in: "Екатеринбурге" },
  izh: { normal: "Ижевск", in: "Ижевске" },
  irk: { normal: "Иркутск", in: "Иркутске" },
  krd: { normal: "Краснодар", in: "Краснодаре" },
  ptr: { normal: "Петрозаводск", in: "Петрозаводске" },
  chl: { normal: "Челябинск", in: "Челябинске" },
  nnov: { normal: "Нижний Новгород", in: "Нижнем Новгороде" },
  nsk: { normal: "Новосибирск", in: "Новосибирске" },
  prm: { normal: "Пермь", in: "Перми" },
};
